<template>
    <VolBox v-model="model" :lazy="true" title="选择申请绩效" :height="600" :width="width" :padding="15"
        ref="selectLocationBox" :onModelClose="closeVolBox">
        <!-- @fullscreen="fullscreen" -->
        <!-- 设置查询条件 -->

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <div class="container">
            <div class="table-wrapper">

                <div style="padding-bottom: 10px">
                    <span style="margin-right: 20px">请输入</span>
                    <el-input placeholder="被考核人姓名" style="width: 200px" v-model="empName" />                    
                    <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i
                            class="el-icon-search"></i>搜索</el-button>
                    <el-button size="medium" type="" style="margin-left:10px"
                        @click="resetCondition"><i></i>重置</el-button>
                </div>

                <vol-table ref="performanceTable" :loadKey="true" :columns="columns" :pagination="pagination"
                    :pagination-hide="false" :height="tableHeight" :url="url" :index="true"
                    :defaultLoadPage="defaultLoadPage" @loadBefore="loadTableBefore" :single="single"
                    @loadAfter="loadTableAfter"></vol-table>
            </div>
        </div>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" @click="addSelected" type="primary"><i class="el-icon-plus"></i>添加</el-button>
                <el-button size="medium" @click="model = false; closeBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {

    },
    data() {
        return {
            width: document.body.clientWidth * 0.8,
            tableHeight: document.body.clientHeight * 0.5,
            model: false,
            defaultLoadPage: false,
            single: true,
            empName: "",
            url: "api/YD_JXExam/GetPageData",//加载数据的接口
            columns: [
                { field: 'JXEID', title: 'JXEID', type: 'int', hidden: true, width: 110, align: 'left', sort: false, },
                { field: 'DeptID', title: '所属部门', type: 'string', bind: { key: 'dept', data: [] }, width: 150, align: 'left' },
                { field: 'Position', title: '考核岗位', type: 'int', width: 200, align: 'left', bind: { key: "绩效考核岗位", data: [] } },
                // { field: 'Position', title: '岗位', type: 'int', width: 120, align: 'left', showAllLevels: true, bind: { key: "岗位列表", data: [] } },
                { field: 'DeptOwner', title: '部门经理', type: 'int', width: 110, align: 'left' },
                { field: 'ExamYear', title: '考核年', type: 'int', width: 60, align: 'left', showAllLevels: true },
                { field: 'ExamMonth', title: '考核月', type: 'int', width: 60, align: 'left', showAllLevels: true },
                { field: 'Staff', title: '被考核人ID', type: 'int', width: 110, align: 'left', hidden: true },
                { field: 'StaffName', title: '被考核人', type: 'string', width: 110, align: 'left' },
                { field: 'DeptOwnerName', title: '上级领导', type: 'int', width: 80, align: 'left' },
                // { field: 'LatitudeLongitude', title: '经纬度', type: 'string', width: 100, align: 'left', link: true },
                { field: 'TotalScore', title: '得分', type: 'int', bind: { key: 'enable', data: [] }, width: 70, align: 'left' },
                { field: 'Level', title: '评级', type: 'string', width: 80, align: 'left', bind: { key: '绩效评级', data: [] } },
                { field: 'jxxs', title: '绩效工资系数', type: 'string', width: 100, align: 'left' },
                { field: 'JXGZ', title: '绩效工资', type: 'string', width: 100, align: 'left' },
                { field: 'Remark', title: '备注', type: 'string', width: 120, align: 'left' },
                { field: 'CreateID', title: '考评人ID', type: 'int', hidden: true, width: 110, align: 'left' },
                { field: 'Creator', title: '考评人', type: 'string', width: 110, align: 'left' },
            ],
            sigle: true,
            selectRow: {},
            pagination: {
                size: 30,
                page: 1,
                rows: 30,
                sizes: [30, 60, 100, 120],
            },
            deptId: 0,
            totalSize: 0,
        };
    },
    setup() {
        return {}
    },
    methods: {
        init(f, value) {
            this.model = true;
            var roleId = this.$store.getters.getUserInfo().baseInfo.roleIds;
            this.single = f;
            if (value != null) {
                this.deptId = value;
            }
            // console.log(this.$store.getters.getUserInfo())
            // if (roleId.indexOf("38") != -1 || roleId == '1') {
            //     this.http.post("/api/Sys_Dictionary/GetVueDictionary", ["质检检查区域"], true).then(sources => {
            //         console.log(sources)
            //         console.log(this.formRules1)
            //         this.formRules[0][0].data = sources[0].data;
            //         this.formRules1[0][0].data = sources[0].data;
            //     })
            // } else {
            //     var dept = this.$store.getters.getUserInfo().baseInfo.deptIds;
            //     this.http.post(`/api/Sys_Department/getArea?id=${dept}`, [{ "headers": { 'Content-Type': 'application/json' } }], true).then(res => {
            //         var areaList = res.map(it => ({ key: it.areaID, value: it.name }));
            //         this.formRules[0][0].data = areaList;
            //         this.formRules1[0][0].data = areaList;
            //     })
            // }

            this.$nextTick(() => {
                this.$refs.performanceTable.reset();
                this.$refs.performanceTable.load();
            });

        },
        openDemo() {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.performanceTable.reset();
                this.$refs.performanceTable.load();
                // this.$refs.mytableRight.reset();
                // this.$refs.mytableRight.load();
            });
        },
        load() {
            this.$refs.performanceTable.load();
        },
        reset() {
            this.$refs.performanceTable.reset();
        },
        addRow() {
            var rows = this.$refs.performanceTable.getSelected();
            console.log(rows);
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            // if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
            //     this.leftTableData = [...this.leftTableDataTemp]
            //     this.leftTableDataTemp = []
            // }
            // var addIds = rows.map(x => x.PositionID)
            // var filter = this.totalData.filter(x => addIds.includes(x.PositionID))
            // if (filter.length > 0) {
            //     var msg = "";
            //     filter.forEach(x => {
            //         msg += x.AreaName + x.Name + ", "
            //     })
            //     return this.$message.error("位置：" + msg + "已添加，请勿重复添加");
            // }
            // rows.forEach(row => {
            //     //this.$refs.mytableRight.addRow({
            //     this.totalData.unshift({
            //         "PositionID": row.PositionID, "AreaID": row.AreaID, "AreaName": row.AreaName, "Name": row.Name, "Code": row.Code,
            //         "IsMustChecked": row.IsMustChecked, "Remarks": row.Remarks, Creator: this.$store.getters.getUserInfo().userId, Creator: this.$store.getters.getUserInfo().userName,
            //         CreateID: this.$store.getters.getUserInfo().userId
            //     });
            // })
            // this.leftTableData = this.paginate(this.totalData, 1, this.pagination.rows).currentPageData
            // this.total = this.totalData.length;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            params.rows = 30
            params.wheres.push({ name: "AuditStatus", value: 1 });
            if (!this.base.checkEmpty(this.deptId)) {
                params.wheres.push({ name: "DeptID", value: this.deptId });
            }
            if (!this.base.checkEmpty(this.empName)) {
                params.wheres.push({ name: "EmpName", value: this.empName });
            }
            // if (!this.base.checkEmpty(this.formFileds.AreaPosName)) {
            //     params.wheres.push({ name: "AreaID", value: this.formFileds.AreaPosName });
            // }
            // if (!this.base.checkEmpty(this.formFileds.LocationName)) {
            //     params.wheres.push({ name: "LocationName", value: this.formFileds.LocationName });
            // }
            // params.wheres.push({ name: "IsRegulation", value: 1 });
            return true;
        },
        search() {
            this.$refs.performanceTable.load();
        },
        resetCondition() {
            this.empName = "";
            this.$refs.performanceTable.load();
        },
        addSelected() {
            // if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
            //     this.leftTableData = [...this.leftTableDataTemp]
            //     var delIds = this.delData.map(x => x.PositionID)
            //     this.leftTableData = this.totalData.filter(x => !delIds.includes(x.PositionID))
            //     this.leftTableDataTemp = []
            // }
            // if (this.totalData != null && this.totalData.length > 0) {
            //     var delIds = this.delData.map(x => x.PositionID)
            //     this.totalData = this.totalData.filter(x => !delIds.includes(x.PositionID))
            // }
            // this.handleClick(this.totalData)
        },
        paginate(data, currentPage, pageSize) {
            const totalItems = data.length;
            // 计算总页数
            const totalPages = Math.ceil(totalItems / pageSize);
            // 计算起始索引和结束索引（注意防止越界）
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, totalItems);
            // 提取当前页数据
            const currentPageData = data.slice(startIndex, endIndex);
            // 返回当前页数据和分页信息
            return {
                currentPageData,
                totalPages,
                totalItems
            };
        },
    },
    emits: ['PerformanceCallBack', 'ClosePerformanceBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('PerformanceCallBack', item)
        }
        const closeBox = (item) => {
            emit('ClosePerformanceBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
    justify-content: space-between;
    /* 在子元素之间分配空间 */
    width: 100%;
    /* 确保容器占据整个可用宽度 */
    /* padding: 0 10 0 10; */
}

.button-container {
    display: flex;
    justify-content: space-between;
    /* 如果你想在按钮之间保持一定的空间 */
    align-items: center;
    /* 垂直居中对齐 */
}

.table-wrapper {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    /* 使每个表格占据相等的空间，或者您可以根据需要设置具体的宽度 */
    /* 其他样式，如边距、内边距等 */
}
</style>