<template>
    <VolBox v-model="model" :lazy="true" title="选择员工" :height="540" :width="800" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="可输入级别、职位、部门、员工姓名" style="width: 250px" v-model="empName" @keyup.enter="search()" />
            <el-select multiple clearable v-model="jobLabel" placeholder="职位标签" style="width:250px;margin-left:20px">
                <el-option v-for="item in jobLabelOptions" :key="item.key" :label="item.value" :value="item.key" />
            </el-select>
            <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i
                    class="el-icon-search"></i>搜索</el-button>
        </div>

        <!-- <VolForm ref="myform" :formFields="formFields" :formRules="formRules">
            <div style="text-align: center; margin-bottom: 5px;float:right;">
                <el-button size="medium" type="primary" style="margin-left:10px" @click="search"><i class="el-icon-search" ></i>搜索</el-button>
            </div>
        </VolForm> -->
        <div v-show="!sigle" style="padding-bottom: 10px;max-height:150px;overflow: auto;">
            <el-tag @close="handleClose(tag)" v-for="tag in tags" :key="tag.empID" closable
                style="margin-right:10px;margin-bottom:5px">
                {{ tag.empName }}
            </el-tag>
        </div>

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" rowKey="empID" :columns="columns" :pagination="pagination"
            :pagination-hide="true" :max-height="380" :url="url" :index="true" :single="sigle"
            :defaultLoadPage="defaultLoadPage" @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"
            @selectionChange="selectionChange"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <dev style="float: left;color:#409eff;font-size: 15px;">总共 {{ count }} 条</dev>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false; closeEmpBox()"><i
                        class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

import { onMounted } from "vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {
        selectRow: Object
    },
    data() {

        return {
            tags: [],
            model: false,
            // status: "1,2,3",//1：在职 2：试用期 3：临时工 4：离职 0：入职审批
            defaultLoadPage: false, //第一次打开时不加载table数据，openDemo手动调用查询table数据
            empName: "", //查询条件字段
            jobLabel: [],//职位标签
            jobLabelOptions: [],//职位标签选项
            JXEID: "",//绩效考核内容YD_JXExamJobDuty ID
            url: "api/Sys_Employee/GetSelectorEmployee",//加载数据的接口
            count: 0,
            columns: [
                { field: "empID", title: "员工ID", width: 90, hidden: true },
                { field: "empName", title: "员工名称", width: 100 },
                { field: 'deptName', title: '所属部门', width: 200, type: 'string' },
                { field: "posName", title: "担任职位", width: 120, type: 'string' },
                // {
                //     field: "opt",
                //     title: "操作",
                //     render: (h, { row, column, index }) => {
                //         return h(
                //             "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                //             h(
                //                 "a", {
                //                 style: {},
                //                 class: [],
                //                 onClick: (e) => {
                //                     e.stopPropagation()
                //                     this.$emit("parentCall", ($parent) => {
                //                         $parent.selectEmp(row);
                //                     })
                //                     this.selectEmpCall(row)
                //                     this.handleClick(row)
                //                     //关闭当前窗口
                //                     this.model = false;
                //                 }
                //             },
                //                 "选择"
                //             ),
                //         ])
                //     }
                // },
            ],
            pagination: { size: 10000 }, //分页配置，见voltable组件api
            sigle: true,//单选，默认true
            formFields: {
                empName: "",
                status: "",
                dept: "",
                position: "",
                level: ""
            },
            formRules: [
                [{
                    title: '员工姓名',
                    filter: true,
                    field: 'empName',
                    type: 'text'
                },
                {
                    dataKey: '职级',
                    data: [],
                    title: '职级',
                    field: 'level',
                    type: 'select'
                }],
                [{
                    dataKey: 'dept-tree',
                    data: [],
                    title: '部门',
                    field: 'dept',
                    type: 'cascader'
                },
                {
                    dataKey: '职位-tree',
                    data: [],
                    title: '职位',
                    field: 'position',
                    type: 'cascader'
                }]
            ],
            wheres: [],
        };
    },
    methods: {
        handleClose(tag) {
            console.log('点击删除', tag);
            this.$refs.mytable.$refs.table.toggleRowSelection(tag);
        },
        selectionChange(args) {
            this.tags = args;
        },
        reset() {
            //this.$refs.myform.reset();
            this.formFields.empName = "";
            this.formFields.dept = "";
            this.formFields.position = "";
            this.formFields.level = "";
            // this.formFields.status = "1,2,3"
            this.empName = '';
            this.$refs.mytable.$refs.table.clearSelection();
            this.$refs.mytable.rowData = [];
        },
        load(JXEID) {
            if (JXEID) {
                this.JXEID = JXEID;
                this.$refs.mytable.load();
            } else {
                this.JXEID = "";
            }
        },
        loadByWheres() {
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        openDemo(sigle, status) {
            this.empName = '';
            // this.status = "1,2,3";
            this.sigle = true;
            if (sigle != null && sigle != undefined) {
                this.sigle = sigle;
            }
            if (status != null && status != undefined) {
                this.status = status;
            }
            this.position = position
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.mytable.load();
            });
        },
        search() {
            //点击搜索
            this.$refs.mytable.load();
        },
        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            this.$emit("parentCall", ($parent) => {
                $parent.selectEmp(rows.length <= 1 ? rows[0] : rows);
            })
            this.handleClick(rows.length <= 1 ? rows[0] : rows)
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) {
            this.count = row.length;
        },
        loadTableBefore(params) {
            //查询前，设置查询条件
            if (this.formFields.status != null && this.formFields.status != '') {
                params.wheres.push({ name: "status", value: this.formFields.status })
            }
            if (!this.base.checkEmpty(this.empName)) {
                params.wheres.push({ name: "cont", value: this.empName })
            }
            if (!this.base.checkEmpty(this.jobLabel)) {
                params.wheres.push({ name: "jobLabel", value: this.jobLabel.join(",") })
            }
            if (this.wheres != null && this.wheres.length > 0) {
                params.wheres.push(...this.wheres)
            }
            //params.wheres.push({ name: "status", value: this.formFields.status })
            //params.wheres.push({ name: "cont", value: this.empName });
            //params.wheres.push({ name: "jobLabel", value: this.jobLabel.join(",") })
            if (this.JXEID) {
                params.wheres.push({ name: "JXEID", value: this.JXEID })
            }
            //params.wheres.push({ name: "empName", value: this.formFields.empName });
            //params.wheres.push({name: "status", value: this.formFields.status})
            //params.wheres.push({name: "dept", value: this.formFields.dept})
            //params.wheres.push({name: "position", value: this.formFields.position})
            //params.wheres.push({name: "level", value: this.formFields.level})
            return true;
        },
        selectEmpCall(row) {
            this.$emit("selectEmpCall", row);
        },
    },
    created() {
        this.http.post("/api/Sys_Dictionary/GetVueDictionary", ["员工职位标签"], true).then(source => {

            this.jobLabelOptions = source[0].data;
        })
    },
    emits: ['EmpCallBack', 'closeEmp'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('EmpCallBack', item)
        }
        const closeEmpBox = (item) => {
            emit('CloseEmpBox', item)
        }
        return {
            handleClick,
            closeEmpBox
        }
    }
};
</script>