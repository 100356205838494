<template>
    <view :footer="false" v-show="model" :padding="0" :lazy="true">
        <div class="audit-model-content" >
            <!-- <el-radio-group v-show="hasFlow" style="padding-left: 15px;" v-model="activeName" class="ml-4">
                <el-radio label="audit" size="large">审核</el-radio>
                <el-radio label="log" size="large">审核记录</el-radio>
            </el-radio-group> -->
            <div
                style="display: flex; line-height: 20px;margin-top:5px; padding-bottom: 5px; border-bottom: 1px solid rgb(238, 238, 238);">
                <div style="height: 19px; background: rgb(45, 206, 217); width: 9px; border-radius: 10px;">
                </div>
                <div style="padding-left: 6px; font-weight: bold; font-size: 13px;">流程信息</div>
            </div>
            <div v-show="activeName == 'audit' || !hasFlow" class="audit-content">
                <div class="fx-left" v-if="hasFlow">
                    <div class="v-steps">
                        <div v-for="(item, index) in workFlowSteps" :key="index">
                            <div class="step-item" v-if="item.stepAttrType == 'start'">
                                <div class="left-item">
                                    <div class="step-title">
                                        {{ item.stepName }}
                                    </div>
                                    <div class="step-text">发起人：{{ item.creator }}</div>
                                    <div class="left-date">发起时间：{{ item.createDate }}</div>
                                </div>
                            </div>
                            <div class="step-item" v-else-if="item.stepAttrType == 'end'">
                                <div class="left-item">
                                    <div class="step-title">
                                        {{ item.stepName }}
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="step-item" v-else-if="item.stepAttrType == 'chaosong'">
                                <div class="left-item">
                                    <div>抄送</div>
                                </div>
                                <div class="right-item">
                                    <div class="step-line"></div>
                                    <i class="step-circle"></i>
                                    <div style="color: #2f95ff !important;font-weight:bold;">
                                        {{ item.stepName }}
                                    </div>
                                </div>
                            </div> -->
                            <div v-else :class="{ 'step-current': item.isCurrent }" class="step-item">
                                <div class="left-item">
                                    <div class="step-line"></div>
                                    <i class="step-circle"></i>
                                    <div class="step-title">
                                        {{ item.stepName }}
                                    </div>
                                    <!-- <div class="left-date">{{ item.createDate ? '发起时间：' + item.createDate :""}}</div> -->
                                    <div class="left-date">{{ item.auditDate ? '审批时间：' + item.auditDate : '审批状态：待审批' }}
                                    </div>
                                    <div class="step-text">审批人：{{ item.auditor ?? "无" }}
                                        {{getTimeConsuming(item.createDate,item.auditDate)}}
                                        <br/>
                                        <span style="color:#e63c3c;" :title="'限时' + item.limitTime + '小时'" v-show="getIsOverTime(item.createDate,item.auditDate,item.limitTime)">超  时：{{'限时' + item.limitTime + '小时'}}</span>
                                    </div>
                                    <div class="step-text">
                                        状 态： {{ getAuditStatus(item.auditStatus) }}
                                    </div>
                                    <div class="step-text">备 注： {{ item.remark || '-' }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="isCurrentUser || !hasFlow || hasDetail" style="margin-top: 20px;;">

                        <div v-if="!hasFlow">
                            <!-- <el-alert :title="'当前选中【' + rowLen + '】条记录待审核..'" type="success" :closable="false" /> -->
                            <!-- <el-alert :title="'当前数据无需审核流程，请先提交审批'" type="error" :closable="false" /> -->
                        </div>
                        <div class="rd" v-else-if="isCurrentUser">
                            <div
                                style="display: flex; line-height: 20px;margin-top:5px; padding-bottom: 5px; border-bottom: 1px solid rgb(238, 238, 238);">
                                <div style="height: 19px; background: rgb(45, 206, 217); width: 9px; border-radius: 10px;">
                                </div>
                                <div style="padding-left: 6px; font-weight: bold; font-size: 13px;">审批</div>
                            </div>
                            <el-radio-group style="margin-left:15px" v-model="auditParam.value">
                                <el-radio v-for="item in auditParam.data" :key="item.value" :label="item.value"
                                    style="margin-right:10px;">
                                    <span>{{ item.text }}</span>
                                </el-radio>
                            </el-radio-group>

                            <el-input style="padding-top: 10px;" v-model="auditParam.reason" type="textarea"
                                :autosize="{ minRows: 4, maxRows: 5 }" placeholder="请输入备注..."></el-input>
                            <div class="btn" style="margin-top: 20px;float:right;">
                                <el-button type="primary" @click="auditRecord" icon="Share">审批记录查看</el-button>
                                <el-button type="primary" @click="auditClick" icon="Check">审批</el-button>
                            </div>
                            <div class="btn" style="margin-top: 20px;float:right;">
                                <el-button type="primary" @click="copyClick" icon="message">抄送</el-button>
                            </div>
                        </div>
                        
                    </div>
                    <div v-else style="float: right;">
                            <el-button type="primary" @click="auditRecord" icon="Share">审批记录查看</el-button>
                            <el-button type="primary" @click="copyClick" icon="message">抄送</el-button>
                        </div>
                </div>


            </div>
            <div v-show="activeName == 'log'">
                <vol-table :tableData="tableData" :columns="columns" :pagination-hide="true" :load-key="false"
                    :text-inline="false" :ck="false"></vol-table>
            </div>

            <VolBox v-model="copy.model" :lazy="true" title="抄送" :height="540" :width="800" :padding="15">
            <vol-form :formRules="copy.formRules"
            :formFields="copy.formFields" style="height:150px;margin:20px 5px;">

            </vol-form>
            <template #footer>
                <div>
                    <el-button size="mini" type="primary" @click="copySave"><i class="el-icon-message"></i>抄送</el-button>
                    <el-button size="mini" @click="copy.model = false;"><i class="el-icon-close"></i>关闭</el-button>
                </div>
            </template>
            </VolBox>
            <Merge ref="merge" @selectEmp="selectEmp"></Merge>
        </div>
    </view>
</template>
<script>
import VolTable from '@/components/basic/VolTable.vue';
import VolBox from '@/components/basic/VolBox.vue';
import VolForm from '@/components/basic/VolForm.vue';
import http from '@/../src/api/http.js';
import { defineComponent, ref, reactive, getCurrentInstance } from 'vue';
import Merge from '@/components/custom/Merge.vue'

export default defineComponent({
    components: {
        VolTable,
        VolBox,
        VolForm,
        Merge,
    },
    props: {
        option: { //生成vue文件的table参数
            type: Object,
            default: {
                key: '',
                cnName: '',
                name: '',
                url: ""
            }
        }
    },
    setup(props, { emit }) {
        const height = ref(500);
        const width = ref(820);
        const model = ref(false)
        const workFlowSteps = reactive([]);

        const hasFlow = ref(false)
        const workFlowTableId = ref("")
        const currentUserID = ref(0)
        const auditParam = reactive({
            //审核对象
            rows: 0, //当前选中审核的行数
            model: false, //审核弹出框
            value: -1, //审核结果
            reason: '', //审核原因
            //审核选项(main.js里面可以添加其他选项)
            data: []
        })
        const { proxy } = getCurrentInstance();
        auditParam.data = proxy.$global.audit.data;
        const tableData = reactive([]);
        const columns = reactive([
            { title: '节点', field: 'stepName', width: 100 },
            { title: '审批人', field: 'auditor', width: 80 },
            { title: '审批结果', field: 'auditStatus', width: 70, bind: { data: [] } },
            { title: '审批时间', field: 'auditDate', width: 145 },
            { title: '备注', field: 'remark', width: 120 }
        ]);

        const detailList = ref([])
        const hasDetail = ref(null);
        const isCurrentUser = ref(null);
        const activeName = ref('audit')

        const auditDic = reactive([]);
        const getAuditStatus = (key) => {
            return (auditDic.find(x => { return x.key === key + '' }) || { value: key }).value;
        }
        const rowLen = ref(0)
        let currentRows = []
        const currentFlowStep = reactive({});

        const getAuditInfo = () => {
            const table = props.option.url.replaceAll('/', '');
            const url = `api/Sys_WorkFlow/getSteps?tableName=${table}`
            //let ids = currentRows.map(x => { return x[props.option.key] });
            let ids = currentRows.map(x => { return x[props.option.key] });
            // ['498043c1-fbd0-4a35-a870-523823912a9b']
            http.post(url, ids, true).then(result => {
                if (!result.status) {
                    proxy.$message.error(result.message);
                    return;
                }

                hasFlow.value = !!(result.list || []).length;
                if (!hasFlow.value) {

                    let auditStatus = Object.keys(currentRows[0]).find(x => { return x.toLowerCase() === 'auditstatus' });

                    let checkStatus = currentRows.every((x) => {
                        return proxy.$global.audit.status.some(c => { return c === x[auditStatus] || !x[auditStatus] || x[auditStatus] == 4 })
                    });
                    if (!checkStatus) {
                        //proxy.$message.error('只能选择待审批或审核中的数据');
                        auditStepLoadAfter();
                        return;
                    }
                    rowLen.value = currentRows.length;
                    model.value = true;
                    width.value = 430;
                    height.value = 330;
                    isCurrentUser.value = true;
                    //没有审批流程的数据只显示
                    auditStepLoadAfter();
                    return;
                }
                model.value = true;
                height.value = document.body.clientHeight * 0.9;
                width.value = 820;
                if (!auditDic.length) {
                    auditDic.push(...(result.auditDic || []))
                    columns.forEach(item => {
                        if (item.field == 'auditStatus') {
                            item.bind.data = auditDic;
                        }
                    })
                }
                currentUserID.value = result.currentUserID
                detailList.value = result.detailList;
                isCurrentUser.value = result.list.some(x => { return x.isCurrentUser })
                workFlowSteps.length = 0;
                workFlowSteps.push(...result.list);
                tableData.length = 0;
                tableData.push(...result.log)
                hasDetail.value = result.detailList != null && result.detailList.length > 0;// result.list.some(x => { return x.detailList != null && x.detailList.length > 0 })
                workFlowTableId.value = result.workFlowTableId;
                var current = result.list.filter(x => { return x.isCurrent })
                currentFlowStep.value = current != null && current.length > 0 ? current[0] : null;
                auditStepLoadAfter();
            })
        }
        //

        const auditClick = () => {
            if (auditParam.value == -1) {
                proxy.$message.error('请选择审批项');
                return;
            }
            emit("auditClick", auditParam, currentRows, (result) => {
                if (result.status) {
                    // model.value = false;
                    // tableData.length = 0;
                }
            }, true, currentFlowStep.value);
        }

        const auditRecord = () => {
            emit("auditRecord", currentRows[0], (result) => {
                
            }, true);
        }
        // 编辑页右边流程数据加载完后回调方法 2023.8.31 左：新加
        const auditStepLoadAfter = () => {
            emit("auditStepLoadAfter",currentRows[0], currentFlowStep.value, workFlowSteps, (result) => {
                
            }, true);
        }

        const open = (rows) => {
            currentRows = rows;
            activeName.value = 'audit'
            auditParam.reason = '';
            auditParam.value = -1;
            getAuditInfo();

        }

        const replyParam = reactive({
            //审核对象
            remarks: '',
            stepId: '',
            empId: 0,
            userId: 0
        })
        const replyClick = (stepId, empId, userId) => {
            replyParam.stepId = stepId;
            replyParam.empId = empId;
            replyParam.userId = userId;
            emit("replyClick", replyParam, currentRows, (result) => {
                if (result.status) {
                    model.value = false;
                    tableData.length = 0;
                }
            });
        }
        const merge = ref(null)
        const copy = ref({
            model: false,
            formRules: [
                [{
                    field: "UserNames", title: "抄送员工", required: true, type: "popup", popup: {
                        idfield: "UserIDs", click: () => {
                            merge.value.openEmployee(false)
                        }
                    }
                }]
            ],
            formFields: {"UserNames":"","UserIDs":""}
        })
        //抄送
        const copyClick = () => {
            copy.value.formFields.UserIDs = ""
            copy.value.formFields.UserNames = ""
            copy.value.model = true
        }
        const copySave = () => {
            var option = props.option
            if (copy.value.formFields.UserIDs == "") {
                proxy.$message.error("抄送员工不能为空");
                return;
            }
            var data = {
                tableName: option.url.replaceAll('/', ''),
                cnName: option.cnName,
                tableKey: currentRows.map(x => { return x[props.option.key] })[0],
                path: "",
                userIds: copy.value.formFields.UserIDs.toString().split(',')
            }
            http.post('api/Sys_WorkFlowTable/CopyToUser', data, true).then(result => {
                if (!result.status) {
                    proxy.$message.error(result.message);
                    return;
                }
                proxy.$message.success(result.message);
                copy.value.model = false
            })
        }
        const selectEmp = (row) => {
            if ((!(row instanceof Array))) {
                row = [row]
            }
            var userids = copy.value.formFields.UserIDs == "" ? [] : copy.value.formFields.UserIDs.split(',')
            var usernames = copy.value.formFields.UserNames == "" ? [] : copy.value.formFields.UserNames.split(',')
            row.forEach(x => {
                var filters = userids.filter(u => u == x.userID)
                if (filters == null || filters.length <= 0) {
                    userids.push(x.userID)
                    usernames.push(x.empName)
                }
            })
            copy.value.formFields.UserIDs = userids.join(',')
            copy.value.formFields.UserNames = usernames.join(',')
        }
        const getTimeConsuming = (createDate,auditDate) => {
            if(!createDate || !auditDate){
                return "";
            }
            const start = new Date(createDate);
            const end = new Date(auditDate);

            // 计算时间差（以毫秒为单位）
            const diff = end - start;
            const timeConsuming = (diff / 1000 / 60 / 60).toFixed(1)
            // 将毫秒转换为分钟
            return "（" + timeConsuming + "小时）";
        }
        const getIsOverTime = (createDate,auditDate,LimitTime) => {
            if(!createDate || !auditDate){
                return "";
            }
            const start = new Date(createDate);
            const end = new Date(auditDate);

            // 计算时间差（以毫秒为单位）
            const diff = end - start;
            const timeConsuming = (diff / 1000 / 60 / 60).toFixed(1)
            let IsOverTime = false
            if(LimitTime != null && timeConsuming > LimitTime && LimitTime >= 0){
                IsOverTime = true
            }
            // 将毫秒转换为分钟
            return IsOverTime;
        }
        return {
            columns,
            height,
            width,
            model,
            workFlowSteps,
            getAuditInfo,
            getAuditStatus,
            activeName,
            reactive,
            tableData,
            auditParam,
            auditClick,
            open,
            isCurrentUser,
            hasFlow,
            rowLen,
            hasDetail,
            replyParam,
            replyClick,
            workFlowTableId,
            detailList,
            currentUserID,
            auditRecord,
            currentFlowStep,
            auditStepLoadAfter,
            copy,
            copyClick,
            merge,
            selectEmp,
            copySave,
            getTimeConsuming,
            getIsOverTime,
        }
    }
});
</script>
    
<style lang="less" scoped>
.audit-model-content {
    padding: 5px;
    width: 100%;
}

.step-item {
    background: #fff;
    display: flex;
}

.left-item {
    min-width: 180px;
    // text-align: right;
    padding-right: 10px;
    padding-top: 8px;

    .left-date {
        font-size: 13px;
        padding-top: 7px;
        color: #6c6c6c;
    }
}

.right-item {
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid #f3f3f3;
    padding: 5px 0 5px 5px;
}

.left-item,
.right-item {
    padding-bottom: 10px;
}

.right-item:last-child {
    border-bottom: 0;
}

.step-line {
    top: 16px;
    left: -10px;
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #ebedf0;
}

.step-circle {
    position: absolute;
    top: 17px;
    left: -9px;
    z-index: 2;
    font-size: 12px;
    line-height: 1;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    background-color: #a1a1a1;
    border-radius: 50%;
}

.right-item::before {
    content: '';
}

.step-content {
    padding-top: 2px;
    font-size: 14px;
    color: #828282;
    line-height: 1.5;
}

.step-title {
    font-weight: bold;
    padding-top: 3px;
}

.step-text {
    font-size: 13px;
    color: #999999;
    padding-top: 6px;
}

.step-current {
    * {
        color: #2f95ff !important;
    }

    .step-circle {
        background: #2f95ff !important;
    }

    // border-radius: 5px;
    // border: 1px solid #d6eaff;
    font-size: 13px;
    padding-top: 6px;
    // background-color: #eff7ffd9;
    color: black;
}

.audit-content {
    // background: #f9f9f9;
    padding: 5px;
    border-radius: 4px;
    display: flex;

    .fx-left {
        flex: 1;
        width: 0;

        .rd {
            display: inline;
            align-items: baseline;
        }
    }

    .fx-right {
        // width: 400px;

        .btn {
            margin-top: 10px;
            text-align: center;
        }
    }

}
</style>