<template>
    <VolBox v-model="model" :lazy="true" title="选择规程文档" :height="740" :width="width" :padding="15">
        <!-- 设置查询条件 -->
        <div style="padding-bottom: 10px">
            <!-- <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="请输入编码、描述、备注、层级" style="width:500px" v-model="Name" @keyup.enter="load()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="load"><i
                    class="el-icon-search"></i>搜索</el-button> -->
            <vol-form ref="myform" :labelWidth="120" :load-key="true" :formFields="formFileds" :formRules="formRules">
                <div style="text-align: right;">
                    <el-button type="primary" @click="load" size="mini"><i class="el-icon-search"></i>查询</el-button>
                    <el-button type="primary" @click="reset" plain size="mini"><i
                            class="el-icon-refresh-right"></i>重置</el-button>
                </div>
            </vol-form>
        </div>
        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <vol-table ref="mytable" :loadKey="true" :columns="columns" :pagination="pagination" :pagination-hide="false"
            :height="540" :url="url" :index="true" :single="sigle" :defaultLoadPage="defaultLoadPage"
            @loadBefore="loadTableBefore" @loadAfter="loadTableAfter"></vol-table>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" type="primary" @click="addRow()">添加</el-button>
                <el-button size="medium" @click="model = false; closeBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";
export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {

    },
    data() {
        return {
            width: document.body.clientWidth * 0.7,
            model: false,
            defaultLoadPage: false,
            formFileds: {
                "InfomationTypeID": "", "Title": ""
            },
            formRules: [
                [
                    { dataKey: "知识库类型-集团规程", "data": [], "title": "知识库类型", "field": "InfomationTypeID", "type": "cascader", showAllLevels: true },
                    { "title": "标题", "field": "Title", "type": "text" }
                ]
            ],
            url: "api/Sys_InformationBase/GetPageData",//加载数据的接口
            columns: [
                { field: 'InfomationTypeID', title: '知识库类型', type: 'int', width: 120, align: 'left', sort: true, bind: { key: '知识库类型', data: [] }, showAllLevels: true },
                { field: 'RegulationType', title: '规程类型', type: 'int', width: 120, align: 'left', sort: true, bind: { key: '规程类型', data: [] } },
                { field: 'Title', title: '标题', type: 'string', width: 220, align: 'left', link: true },
                { field: 'DeptID', title: '部门', type: 'int', bind: { key: 'dept', data: [] }, width: 150, align: 'left' },
                { field: 'Code', title: '编号', type: 'string', width: 110, align: 'left' },
                { field: 'Files', title: '规程附件', type: 'file', width: 110, align: 'left', hidden: true },
                {
                    field: "opt",
                    title: "操作",
                    render: (h, { row, column, index }) => {
                        return h(
                            "div", { style: { 'font-size': '13px', 'cursor': 'pointer', 'color': '#409eff' } }, [
                            h(
                                "a", {
                                style: {},
                                class: [],
                                onClick: (e) => {
                                    e.stopPropagation()
                                    this.handleClick(row)
                                    //关闭当前窗口
                                    this.model = false;
                                }
                            },
                                "选择"
                            ),
                        ])
                    }
                },
            ],
            sigle: true,
        };
    },
    methods: {
        init() {
            this.model = true;
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytable.reset();
                this.$refs.mytable.load();
            });
        },
        init(f) {
            this.model = true;
            this.sigle = f;
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytable.reset();
                this.$refs.mytable.load();
            });
        },
        load() {
            this.$refs.mytable.load();
        },
        reset() {
            this.$refs.myform.reset()
        },
        openDemo() {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytable.reset();
                this.$refs.mytable.load();
            });
        },

        addRow() {
            var rows = this.$refs.mytable.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            // this.$emit("parentCall", ($parent) => {
            //     $parent && $parent.SelectDevice(rows);
            // })
            this.handleClick(rows);
            //关闭当前窗口
            this.model = false;
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            params.rows = 100
            if (!this.base.checkEmpty(this.formFileds.InfomationTypeID)) {
                params.wheres.push({ name: "InfomationTypeID", value: this.formFileds.InfomationTypeID[this.formFileds.InfomationTypeID.length - 1] });
            }
            if (!this.base.checkEmpty(this.formFileds.Title)) {
                params.wheres.push({ name: "Title", value: this.formFileds.Title, displayType: "like" });
            }
            params.wheres.push({ name: "IsRegulation", value: 1 });
            return true;
        },
    },
    emits: ['InfomationBaseCallBack', 'CloseInfomationBaseBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('InfomationBaseCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseInfomationBaseBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
};
</script>