<template>
    <VolBox v-model="model" :lazy="true" title="选择适用位置" :height="600" :width="width" :padding="15"
        ref="selectLocationBox" :onModelClose="closeVolBox">
        <!-- @fullscreen="fullscreen" -->
        <!-- 设置查询条件 -->

        <!-- vol-table配置的这些属性见VolTable组件api文件 -->
        <div class="container">
            <div class="table-wrapper">
                <el-alert title="选择位置" type="warning" :closable="false" />
                <div style="padding: 5px 0">
                    <!-- <span style="margin-right: 20px">请输入</span>
            <el-input placeholder="请输入编码、描述、备注、层级" style="width:500px" v-model="Name" @keyup.enter="load()" />
            <el-button size="medium" type="primary" style="margin-left:10px" @click="load"><i
                    class="el-icon-search"></i>搜索</el-button> -->
                    <vol-form ref="myform" :labelWidth="100" :load-key="true" :formFields="formFileds"
                        :formRules="formRules">
                        <div class="button-container">
                            <div style="text-align: left;">
                                <el-button type="primary" @click="load" size="mini"><i
                                        class="el-icon-search"></i>查询</el-button>
                                <el-button type="primary" @click="reset" plain size="mini"><i
                                        class="el-icon-refresh-right"></i>重置</el-button>
                            </div>
                            <div style="text-align: right;"><el-button size="medium" type="success"
                                    @click="addRow()">添加</el-button></div>
                        </div>
                    </vol-form>
                </div>

                <vol-table ref="mytableLeft" :loadKey="true" :columns="columns" :pagination="pagination"
                    :pagination-hide="false" :height="LeftTableHeight" :width="leftTableWidth" :url="url" :index="true"
                    :defaultLoadPage="defaultLoadPage" @loadBefore="loadTableBefore"
                    @loadAfter="loadTableAfter"></vol-table>


            </div>
            <div class="table-wrapper">
                <!-- <div style="padding-bottom: 100px"></div> -->
                <el-alert title="已选择位置" type="success" :closable="false" />

                <div style="text-align: right; padding: 5px 0;">
                    <vol-form ref="myform1" :labelWidth="100" :load-key="true" :formFields="formFileds1"
                        :formRules="formRules1">
                        <div class="button-container">
                            <div style="text-align: left;">
                                <el-button type="primary" @click="load1" size="mini"><i
                                        class="el-icon-search"></i>查询</el-button>
                                <el-button type="primary" @click="reset1" plain size="mini"><i
                                        class="el-icon-refresh-right"></i>重置</el-button>
                            </div>
                            <el-button size="medium" type="danger" @click="delRow()"
                                style="margin-right: 25px;">删除</el-button>
                        </div>
                    </vol-form>

                </div>
                <vol-table ref="mytableRight" :loadKey="true" :columns="columns1" :pagination="pagination"
                    :pagination-hide="true" :height="RightTableHeight" :width="rightTableWidth"
                    :tableData="leftTableData" :index="true" :defaultLoadPage="defaultLoadPage">
                </vol-table>
                <div style="text-align: right;">
                    <el-pagination key="pagination-02" @size-change="handleSizeChange" style="text-align: right;"
                        @current-change="handleCurrentChange" :current-page="pagination.page"
                        :page-sizes="pagination.sizes" :page-size="pagination.size"
                        layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
                </div>
            </div>

        </div>
        <!-- 设置弹出框的操作按钮 -->
        <template #footer>
            <div>
                <el-button size="medium" @click="save" type="primary"><i class="el-icon-plus"></i>保存</el-button>
                <el-button size="medium" @click="closeVolBox(); closeBox()"><i class="el-icon-close"></i>关闭</el-button>
            </div>
        </template>
    </VolBox>
</template>
<script>
import VolForm from "@/components/basic/VolForm.vue";
import VolBox from "@/components/basic/VolBox.vue";
import VolTable from "@/components/basic/VolTable.vue";

export default {
    components: {
        VolBox: VolBox,
        VolTable: VolTable,
        VolForm: VolForm
    },
    props: {

    },
    data() {
        return {
            width: document.body.clientWidth,
            model: false,
            defaultLoadPage: false,
            LeftTableHeight: document.body.clientHeight - 350,
            RightTableHeight: document.body.clientHeight - 350,
            leftTableWidth: document.body.clientWidth * 0.5,
            rightTableWidth: document.body.clientWidth * 0.5,
            total: 0,
            formFileds: {
                "DeptID": "", "AreaPosName": "", "LocationName": ""
            },
            formRules: [
                [
                    // { "dataKey": "dept-tree", "data": [], "title": "选择部门", "field": "DeptID", "type": "cascader", onChange: this.AreaChange },
                    { "title": "选择区域", "field": "AreaPosName", "type": "select", data: [] },//"dataKey": "质检检查区域",
                    { "title": "输入位置", "field": "LocationName", "type": "like", }
                ]
            ],
            formFileds1: {
                "DeptID": "", "AreaPosName": "", "LocationName": ""
            },
            formRules1: [
                [
                    // { "dataKey": "dept-tree", "data": [], "title": "选择部门", "field": "DeptID", "type": "cascader", onChange: this.AreaChange },
                    { "title": "选择区域", "field": "AreaPosName", "type": "select", data: [] },//"dataKey": "质检检查区域",
                    { "title": "输入位置", "field": "LocationName", "type": "like", }
                ]
            ],
            url: "api/Qua_Position/GetPageData",//加载数据的接口
            url1: "api/Qua_CheckContItemArea/GetPageData",
            columns: [
                { field: 'PositionID', title: 'PositionID', type: 'int', hidden: true, width: 110, align: 'left', sort: false, },
                { field: 'AreaID', title: 'AreaID', type: 'int', hidden: true, width: 110, align: 'left', sort: true, },
                { field: 'AreaName', title: '区域名称', type: 'int', width: 120, align: 'left', showAllLevels: true },
                { field: 'Name', title: '位置名称', type: 'int', width: 120, align: 'left', showAllLevels: true },
                { field: 'Code', title: '位置编码', type: 'int', width: 120, align: 'left' },
                // { field: 'LatitudeLongitude', title: '经纬度', type: 'string', width: 100, align: 'left', link: true },
                { field: 'IsMustChecked', title: '必查项', type: 'int', bind: { key: 'enable', data: [] }, width: 70, align: 'left' },
                { field: 'Remarks', title: '备注', type: 'string', width: 120, align: 'left' },
            ],
            columns1: [
                { field: 'PositionID', title: 'PositionID', type: 'int', hidden: true, width: 110, align: 'left', },
                { field: 'AreaID', title: 'AreaID', type: 'int', hidden: true, width: 110, align: 'left', sort: true, },
                { field: 'AreaName', title: '区域名称', type: 'int', width: 120, align: 'left', showAllLevels: true },
                { field: 'Name', title: '位置名称', type: 'int', width: 120, align: 'left', showAllLevels: true },
                { field: 'Code', title: '位置编码', type: 'int', width: 110, align: 'left' },
                // { field: 'LatitudeLongitude', title: '经纬度', type: 'string', width: 100, align: 'left', link: true },
                { field: 'IsMustChecked', title: '必查项', type: 'int', bind: { key: 'enable', data: [] }, width: 70, align: 'left' },
                { field: 'Creator', title: '创建人', type: 'string', width: 70, align: 'left' },
                { field: 'Remarks', title: '备注', type: 'string', width: 120, align: 'left' },
            ],
            sigle: true,
            selectRow: {},
            leftTableData: [],
            totalData: [],
            leftTableDataTemp: [],
            delData: [],
            searchData: [],
            pagination: {
                size: 30,
                page: 1,
                rows: 30,
                sizes: [30, 60, 100, 120],
            },
            totalSize: 0,
        };
    },
    setup() {
        return {}
    },
    methods: {
        init() {
            this.model = true;
            // this.formRules.forEach(item => {
            //     if(item.field == "DeptID"){
            //         debugger
            //         item.onChange = (val)=>{
            //             this.http.post(`/api/OA_Department/getArea?id=${val}`,null,true).then(res=>{
            //                 console.log(res)
            //                 var areaList = source.map(it => ({ key: it.id, value: it.name }));;
            //                 this.formRules[1].data = areaList
            //                 console.log(this.formRules)
            //             })
            //         }
            //     }
            // });
            var roleId = this.$store.getters.getUserInfo().baseInfo.roleIds;
            // console.log(this.$store.getters.getUserInfo())
            if (roleId.indexOf("38") != -1 || roleId == '1') {
                this.http.post("/api/Sys_Dictionary/GetVueDictionary", ["质检检查区域"], true).then(sources => {
                    console.log(sources)
                    console.log(this.formRules1)
                    this.formRules[0][0].data = sources[0].data;
                    this.formRules1[0][0].data = sources[0].data;
                })
            } else {
                var dept = this.$store.getters.getUserInfo().baseInfo.deptIds;
                this.http.post(`/api/Sys_Department/getArea?id=${dept}`, [{ "headers": { 'Content-Type': 'application/json' } }], true).then(res => {
                    var areaList = res.map(it => ({ key: it.areaID, value: it.name }));
                    this.formRules[0][0].data = areaList;
                    this.formRules1[0][0].data = areaList;
                })
            }

            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytableLeft.reset();
                this.$refs.myform1.reset()
                // this.$refs.mytableLeft.load();
                //this.$refs.mytableRight.reset();
                this.totalData = this.selectRow.Qua_CheckContItemArea || [];
                this.total = this.totalData.length
                const { currentPageData, totalPages } = this.paginate(this.totalData, 1, this.pagination.size);
                this.leftTableData = currentPageData
                // this.resetTableRightPage()
                this.$refs.selectLocationBox.handleFullScreen();
            });
            this.$refs.mytableRight.paginations.total = this.selectRow.Qua_CheckContItemArea.length

        },
        openDemo() {
            this.model = true;
            //打开弹出框时，加载table数据
            this.$nextTick(() => {
                this.$refs.myform.reset()
                this.$refs.mytableLeft.reset();
                // this.$refs.mytableLeft.load();
                // this.$refs.mytableRight.reset();
                // this.$refs.mytableRight.load();
            });
        },
        load() {
            this.$refs.mytableLeft.load();
        },
        reset() {
            this.$refs.myform.reset();
            this.$refs.mytableLeft.reset();
        },
        load1() {
            this.leftTableDataTemp = []
            this.leftTableDataTemp = [...this.leftTableData]
            this.searchData = this.selectRow.Qua_CheckContItemArea
            if (this.delData.length > 0) {
                this.searchData = this.selectRow.Qua_CheckContItemArea.filter(x => !this.delData.map(i => i.PositionID).includes(x.PositionID))
            }
            if (this.formFileds1.AreaPosName != null && this.formFileds1.AreaPosName != '' && this.formFileds1.AreaPosName != undefined)
                this.searchData = this.searchData.filter(x => x.AreaID == this.formFileds1.AreaPosName)
            if (this.formFileds1.LocationName != null && this.formFileds1.LocationName != '' && this.formFileds1.LocationName != undefined)
                this.searchData = this.searchData.filter(x => x.Name.includes(this.formFileds1.LocationName))
            this.leftTableData = this.searchData
            //this.$refs.mytableRight.paginations.total = this.leftTableData.length
            this.resetTableRightPage()
            // this.sreachData = this.sreachData.filter(x=>x.AreaName == this.formFileds1.AreaPosName)
            // debugger
            // this.$refs.mytableRight.load();
        },
        reset1() {
            this.$refs.myform1.reset();
            if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
                this.leftTableData = [...this.leftTableDataTemp]
                this.leftTableDataTemp = []
            }
            else {
                this.leftTableData = this.selectRow.Qua_CheckContItemArea
            }
            if (this.delData.length > 0) {
                this.leftTableData = this.totalData.filter(x => !this.delData.map(i => i.PositionID).includes(x.PositionID))
            }
            this.resetTableRightPage()
            //this.leftTableData = this.selectRow.Qua_CheckContItemArea
            // this.$refs.mytableRight.paginations.total = this.selectRow.Qua_CheckContItemArea.length
            // this.$refs.mytableRight.reset();
        },
        addRow() {
            var rows = this.$refs.mytableLeft.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
                this.leftTableData = [...this.leftTableDataTemp]
                this.leftTableDataTemp = []
            }
            var addIds = rows.map(x => x.PositionID)
            var filter = this.totalData.filter(x => addIds.includes(x.PositionID))
            if (filter.length > 0) {
                var msg = "";
                filter.forEach(x => {
                    msg += x.AreaName + x.Name + ", "
                })
                return this.$message.error("位置：" + msg + "已添加，请勿重复添加");
            }
            rows.forEach(row => {
                //this.$refs.mytableRight.addRow({
                this.totalData.unshift({
                    "PositionID": row.PositionID, "AreaID": row.AreaID, "AreaName": row.AreaName, "Name": row.Name, "Code": row.Code,
                    "IsMustChecked": row.IsMustChecked, "Remarks": row.Remarks, Creator: this.$store.getters.getUserInfo().userId, Creator: this.$store.getters.getUserInfo().userName,
                    CreateID: this.$store.getters.getUserInfo().userId
                });
            })
            this.leftTableData = this.paginate(this.totalData, 1, this.pagination.rows).currentPageData
            this.total = this.totalData.length;
        },
        delRow() {
            var rows = this.$refs.mytableRight.getSelected();
            if (!rows || rows.length == 0) {
                return this.$message.error("请选择行数据");
            }
            var userId = this.$store.getters.getUserInfo().userId;
            var w = rows.filter(x => x.CreateID != userId)
            if (w != null && w.length > 0) {
                return this.$message.error("您选择的数据中有非您本人添加的数据，无法删除")
            }
            this.delData = rows
            var delIds = this.delData.map(x => x.PositionID)
            this.leftTableData = this.leftTableData.filter(x => !delIds.includes(x.PositionID))
            this.totalData = this.totalData.filter(x => !delIds.includes(x.PositionID))
            this.resetTableRightTotalNum()
            //this.$refs.mytableRight.delRow(rows[0].PositionID);
        },
        //这里是从api查询后返回数据的方法
        loadTableAfter(row) { },
        loadTableBefore(params) {
            // params.rows = 30
            if (!this.base.checkEmpty(this.formFileds.DeptID)) {
                params.wheres.push({ name: "DeptID", value: this.formFileds.DeptID[this.formFileds.DeptID.length - 1] });
            }
            if (!this.base.checkEmpty(this.formFileds.AreaPosName)) {
                params.wheres.push({ name: "AreaID", value: this.formFileds.AreaPosName });
            }
            if (!this.base.checkEmpty(this.formFileds.LocationName)) {
                params.wheres.push({ name: "LocationName", value: this.formFileds.LocationName });
            }
            // params.wheres.push({ name: "IsRegulation", value: 1 });
            return true;
        },
        save() {
            if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
                this.leftTableData = [...this.leftTableDataTemp]
                var delIds = this.delData.map(x => x.PositionID)
                this.leftTableData = this.totalData.filter(x => !delIds.includes(x.PositionID))
                this.leftTableDataTemp = []
            }
            if (this.totalData != null && this.totalData.length > 0) {
                var delIds = this.delData.map(x => x.PositionID)
                this.totalData = this.totalData.filter(x => !delIds.includes(x.PositionID))
            }
            this.handleClick(this.totalData)
        },
        resetTableRightTotalNum() {
            this.total = this.totalData.length;
        },
        resetTableRightPage() {
            this.total = this.leftTableData.length;
            // this.$refs.mytableRight.paginations.total = this.leftTableData.length
        },
        closeVolBox() {
            if (this.leftTableDataTemp != null && this.leftTableDataTemp.length > 0) {
                this.leftTableData = [...this.leftTableDataTemp]
                this.leftTableDataTemp = []
            }
            this.model = false
        },
        paginate(data, currentPage, pageSize) {
            const totalItems = data.length;
            // 计算总页数
            const totalPages = Math.ceil(totalItems / pageSize);
            // 计算起始索引和结束索引（注意防止越界）
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = Math.min(startIndex + pageSize, totalItems);
            // 提取当前页数据
            const currentPageData = data.slice(startIndex, endIndex);
            // 返回当前页数据和分页信息
            return {
                currentPageData,
                totalPages,
                totalItems
            };
        },
        handleSizeChange(val) {
            this.pagination.size = val;
            this.pagination.rows = val;
            this.leftTableData = this.paginate(this.totalData, this.pagination.size, this.pagination.rows).currentPageData;
        },
        handleCurrentChange(val) {
            this.pagination.page = val;
            this.leftTableData = this.paginate(this.totalData, val, this.pagination.rows).currentPageData;
            this.paginate(this.totalData, val, this.pagination.rows);
        },
        // AreaChange(val) {
        //     var value = val[val.length - 1];
        //     this.http.post(`/api/Sys_Department/getArea?id=${value}`, [{ "headers": { 'Content-Type': 'application/json' } }], true).then(res => {
        //         var areaList = res.map(it => ({ key: it.areaID, value: it.name }));;
        //         this.formRules[0][1].data = areaList;
        //     })
        // },
        // fullscreen(){
        //     console.log(document.body.clientHeight * 0.95 - 60)
        // }
    },
    emits: ['AreasCallBack', 'CloseAreasBox'],
    setup(props, { emit }) {
        const handleClick = (item) => {
            emit('AreasCallBack', item)
        }
        const closeBox = (item) => {
            emit('CloseAreasBox', item)
        }
        return {
            handleClick,
            closeBox
        }
    }
}
</script>
<style scoped>
.container {
    display: flex;
    justify-content: space-between;
    /* 在子元素之间分配空间 */
    width: 100%;
    /* 确保容器占据整个可用宽度 */
    /* padding: 0 10 0 10; */
}

.button-container {
    display: flex;
    justify-content: space-between;
    /* 如果你想在按钮之间保持一定的空间 */
    align-items: center;
    /* 垂直居中对齐 */
}

.table-wrapper {
    flex: 1;
    padding-left: 5px;
    padding-right: 5px;
    /* 使每个表格占据相等的空间，或者您可以根据需要设置具体的宽度 */
    /* 其他样式，如边距、内边距等 */
}
</style>