<template>
  <div>
    <!-- 这里filter有什么用  searchFormFields?-->
    <!-- 
        :filterable="singleSearch.filter || singleSearch.data.length > 10 ? true : false"
    -->
    <el-select
      style="width: 150px"
      v-if="['select', 'selectList'].indexOf(localSearchInfo?.type) != -1"
      v-model="searchFormFields[localSearchInfo?.field]"
      :placeholder="'请选择' + localSearchInfo.title"
      clearable
    >
      <el-option
        v-for="item in localSearchInfo.data"
        :key="item.key"
        :label="item.value"
        :value="item.key"
      >
      </el-option>
    </el-select>
    <!-- <div
      class="date-range"
      v-else-if="['date', 'datetime'].indexOf(localSearchInfo.type) != -1"
    >
      <el-date-picker
        style="width: 210px"
        :clearable="false"
        unlink-panels
        v-model="localSearchInfo"
        type="daterange"
        :value-format="getDateFormat(localSearchInfo)"
        :placeholder="localSearchInfo.title"
      >
      </el-date-picker>
      <i
        class="el-icon-circle-close"
        @click="dateRangeClear(localSearchInfo)"
      ></i>
    </div>
    <el-cascader
      style="width: 210px"
      clearable
      v-model="localSearchInfo"
      v-else-if="localSearchInfo.type == 'cascader'"
      :options="localSearchInfo.data"
      :show-all-levels="false"
      :props="{ checkStrictly: true }"
      filterable
    >
    </el-cascader>
    <el-input
      clearable
      v-else
      style="width: 150px"
      size="default"
      v-model="localSearchInfo"
      :placeholder="localSearchInfo.title"
      @keypress="tiggerPress"
    /> -->
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { onMounted ,computed} from 'vue';


   const props= defineProps({
        searchInfo:{
            type:Array,
            requierd:false
        },
        searchFormFields: {
            type: Object,
            default: () => {
            return {};
      },
    },
    })

    const emit = defineEmits(['update:searchInfo'])

    const localSearchInfo = computed({
        get: () => props.searchInfo,
        set: (value) => emit('update:searchInfo', value)
        })


    function compareDate(date1, date2) {
      if (!date2) {
        return true;
      }
      return (
        date1.valueOf() <
        (typeof date2 == "number" ? date2 : new Date(date2).valueOf())
      );
    }

    function quickSearchKeyPress($event) {
        //查询字段为input时，按回车查询
        if ($event.keyCode == 13) {
            // console.log($event);
        }

    }

    function getDateFormat(item) {
      //见https://day.js.org/docs/zh-CN/display/format
      return item.type == "date" ? "YYYY-MM-DD" : "YYYY-MM-DD HH:mm:ss";
    }

    function getDateOptions(date, item) {
      if ((!item.min && !item.max) || !date) {
        return false;
      }
      if (item.min && item.min.indexOf(" ") == -1) {
        //不设置时分秒，后面会自动加上 08:00
        item.min = item.min + " 00:00:000";
      }
      return (
        compareDate(date, item.min) || !compareDate(date, item.max)
      );
    }
    
    function dateRangeClear(field) {
        searchInfo=[undefined,undefined]
        emit('update:searchInfo',searchInfo)
    }

    watch(()=>props,()=>{
        // console.log(props.searchFormFields);
        
    })

    onMounted(()=>{
        // console.log(props.searchInfo,props.searchFormFields);
        // console.log(props.searchFormFields[localSearchInfo.field]);
        
    })


    

    defineExpose({
        quickSearchKeyPress,
        getDateFormat,
        dateRangeClear
    })

</script>

<style>

</style>